import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldBarcarola: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Barcarola?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_barcarola.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Barcarola?</h1>
          <h2>
            Not sure if pulling Barcarola is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>27/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="barcarola"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>You should pull Barcarola</p>
        <ul>
          <li>
            If you want a team that once set up easily deals high damage and is
            fairly straightforward.
          </li>
          <li>
            Planning to run [Impromptu Incantation] teams for the foreseeable
            future.
          </li>
          <li>
            Unironically, Matilda fans, we rise, this is our friend. (Also,
            Voyager too? OG unit buddies??)
          </li>
        </ul>
        <p>
          If you want to pull for Barcarola for meta reasons, she might be
          skippable, even though she’s quite strong. She requires specific units
          to work effectively. However, if you can build the right team
          composition for her, she becomes very powerful. She focuses on
          [Impromptu Incantation], which deals powerful mental damage, and
          passively boosts damage with her [Inspiration] mechanic.
        </p>
        <p>
          However, she requires a team built around [Eureka] and [Inspiration]
          to truly shine, meaning substantial investment in the Euphoria of
          characters like Matilda and Voyager. If you are willing to invest
          those resources, Barcarola offers an exciting and high-damage
          playstyle. It should also be noted that she causes other characters to
          lose their gained Eureka, meaning that if you want to use a character
          for their effect when they consume Eureka, she might not be the most
          suitable choice.
        </p>
        <p>
          If you do have the right team for her, she’s not very difficult to
          use, making her a solid choice if you prefer a straightforward
          playstyle.
        </p>
        <SectionHeader title="Review" />
        <p>
          Barcarola is the core of a team that focuses on [Impromptu
          Incantation], an incantation that deals Mental damage after all
          allies' cards have been used. What exactly is it? It’s a randomly
          targeted attack, increasing in power with more hits if you have a high
          count of [Inspiration] stacks. Each hit is randomly targeted at a
          random enemy, which can result in very high damage.
        </p>
        <p>
          [Inspiration] is gained when an ally consumes Eureka (Her Insight I
          effect). She can also consume Eureka using her skill, “Music of the
          Heart”, which consumes 2 Eureka. It’s a single-target attack that
          deals more damage when three or more characters are in [Quartet]
          (which I will discuss later). It also increases [Impromptu
          Incantation]'s damage, even more so if you meet the [Quartet]
          conditions I mentioned earlier.
        </p>
        <p>
          Her other skill also gives her Eureka. "Cascading Arpeggios" consumes
          2 Eureka and is also a single-target attack. Instead of increasing
          damage, it increases the number of hits that [Impromptu Incantation]
          will perform, and this increase is further amplified when you have
          [Quartet], similar to the other skill.
        </p>
        <p>
          Her ultimate, “Sea Breeze Serenade,” is where [Quartet] is gained.
          It’s a powerful buff that increases the attack of both yourself and
          your target's ATK on your team, and grants [Inspiration] after using
          an Attack Incantation, making them consume 1 Eureka. It also provides
          a flat Moxie +1 and Eureka +3 when you initially use the buff, making
          it a strong buff overall.
        </p>
        <p>
          As you gain stacks of [Inspiration] from [Eureka], you strengthen the
          attack potential of your [Impromptu Incantations], not only through
          the sheer number of hits but also by increasing its critical rate and
          damage. The more you use it in a round, the higher its crit rate/DMG
          becomes, thanks to her Insight III. Essentially, you just gain passive
          damage by having Eureka characters in your team, which as suggested
          means you do need characters that help her with this in order to
          achieve high damage. It’s fairly easy to use if you have the right
          team.
        </p>
        <p>
          For her to function, you'll likely need characters like Matilda (I
          know you're making the Pikachu shocked face right now) and someone
          like Voyager. Both of these characters were buffed with [Euphoria] and
          now directly correlate their skills with [Impromptu Incantation]
          teams. While that’s great, Euphoria costs a lot of resources. So, not
          only is she a 6-star unit you need to pull for, which is core to the
          team, but you also have to invest a lot of resources into her allies.
          Even her best teammates, who don’t require or have Euphoria, are still
          mostly 6-stars, which is still costly to invest in or even pull for in
          the first place.
        </p>
        <p>
          While any [Eureka] character will likely benefit her, if you want to
          utilize the effects of a character that consumes their own [Eureka],
          she might not be the best choice. This is because that character will
          lose their Eureka really fast when paired in a team with her and
          likely unable to activate their own effects.
        </p>
        <p>
          However, if you’re willing to invest, Barcarola offers an exciting new
          playstyle with impressive damage potential. Once set up, you’ll have a
          character with high passive damage, requiring minimal effort in actual
          battle.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Core unit for building powerful [Impromptu Incantation]
                    teams.
                  </li>
                  <li>
                    High damage potential with multiple hits from [Impromptu
                    Incantation].
                  </li>
                  <li>
                    Once the team is set up, she’s quite easy to use with
                    passive damage.
                  </li>
                  <li>
                    Ultimate provides buffs to herself and allies, providing
                    Eureka, and even Moxie.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Requires a specific team setup to perform well.</li>
                  <li>Her best teammates can have high investment issues.</li>
                  <li>
                    Might consume Eureka too quickly if you needed it for an
                    effect of another character.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          Her portrays are honestly quite good, so it's unfortunate that she's a
          6-star, but you don't necessarily need it.
        </p>
        <p>
          Portray level 2 will increase [Impromptu Incantation] hit rate and
          crit damage by 15%, which is really good, and level 3, which increases
          [Impromptu Incantation] hits by 2, is also beneficial. Level 4 is a
          simple damage buff, and level 5 is also really strong as it allows you
          to gain Inspiration faster. You don’t need any of it if you have the
          right teammate, but it certainly helps if you have the budget for it.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Really any character that has Eureka will be relatively useful in a
          team with her, however there are some units that are clearly designed
          for her. You can also use any Crit person as possible filler.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="voyager"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="matilda"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="flutterpage"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Voyager: Thanks to her Euphoria upgrade, Voyager gains abilities
          directly related to Eureka and [Inspiration]. She can increase
          [Impromptu Incantation] attack hits, provide other benefits like
          debuffs, help gain [Inspiration], and, as [Impromptu Incantation] is
          mostly random, she can apply [Orbital Direction] on an enemy to make
          the move focus on hitting that target and also allow it to deal extra
          Genesis DMG.
        </p>
        <p>
          Matilda: Matilda has been a very underwhelming character, but with her
          Euphoria buff, this is where she'll shine from now on. She can
          increase the crit rate of [Impromptu Incantations] and gain more Crit
          DMG. Her ultimate gains [Inspiration] and performs the same function
          as her skills, increasing [Impromptu Incantation] crit rate and
          damage.
        </p>
        <p>
          Flutterpage: She’s just a very strong character, while she doesn’t
          deal in the archetype specifically. She just has a really high number
          of Eureka generation which will in turn help the team gain
          [Inspiration] faster.
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Barcarola:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="addio-coda"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="belt-it-out"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Addio Coda is her best in slot pick. You gain [Inspiration] and you
          increase the damage of [Impromptu Incantation].
        </p>
        <p>
          Belt It Out is a simple DMG boost for Star Afflatus characters, which
          are probably most of her teammate options.
        </p>
        <SectionHeader title="Resonance & Resonance Pattern" />
        <h5>Resonances</h5>
        <h6>R10 Build (Recommended)</h6>
        <StaticImage
          src="../../../images/reverse/generic/barcarola_r_1.webp"
          alt="Guides"
        />
        <h5>Resonance Pattern</h5>
        <p>
          <strong>Genuinity</strong>
        </p>
        <p>
          <strong>Aspirational</strong>
        </p>
        <SectionHeader title="Teams" />
        <p>
          A A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Pew Pew Pew
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="barcarola"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="matilda"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS/Buffer</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="voyager"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Tooth Fairy</strong>
              </li>
              <li>
                A strong "Impromptu Incantation" Support comp focused around
                maximizing its damage output and its hit frequency. Due to
                Matilda (and Voyager, both with Euphoria) being present, it is
                also easier to build for newer players. Tooth Fairy is here
                because she is more AP-friendly than Vila.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            I don’t wanna Euphoria 2 whole characters.
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="barcarola"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="voyager"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS/Buffer</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                3rd Slot can be Literally anyone with Eureka (Preferably
                Flutterpage)
              </li>
              <li>
                4th slot options: <strong>Tooth Fairy</strong>
              </li>
              <li>
                If you don’t want to upgrade the Euphoria for both Voyager and
                Matilda, which is, of course, very costly, I think Voyager
                should be prioritized (though using just Matilda should be fine
                too if you don’t have Voyager). She's a very solid all-around
                support core that grants the team access to [Impromptu
                Incantations] while providing solid offensive and defensive
                support capabilities. You can then fill out the team with
                someone else who has Eureka and probably someone to keep you
                alive. Tooth Fairy works, Vila works. However, if you’re really
                on a budget but still want to use her, and you don’t have anyone
                else for Eureka aside from Matilda (Who you 100% have because
                she’s give to you) or Voyager, you can try focusing on lower-end
                crit support like Twin Sleep and Erick or Baby Blue. They won’t
                be the best options and should be replaced later, but they
                should be fine for a decent amount of content.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldBarcarola;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Barcarola? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Barcarola is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
